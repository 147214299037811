@tailwind base;
@tailwind components;
@tailwind utilities;

html{
    height: 100%;
}
#root{
    display: flex;
    flex-direction: column;
    height: 100%;
}
nav{
    /* 0 flex-grow, 0 flex-shrink, auto flex-basis */
    flex: 0 0 auto;
}
.main-content{
    /* 1 flex-grow, 0 flex-shrink, auto flex-basis */
    flex: 1 0 auto;
}
footer{
    /* 0 flex-grow, 0 flex-shrink, auto flex-basis */
    flex: 0 0 auto!important;
}